import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

const lang = {
  'fr': {
    home: 'Accueil',
    campaigns: 'Campagnes',
    wallet: 'Portefeuille',
    notification: 'Notifications',
    contact: 'Contacts',
    homeSubtitle: 'Tableau de bord',
    newCampaigns: 'Démarrer une campagne',
    funding: 'Recharger',
    expense: 'Dépenses',
    nbrSee: 'Nombre de vues',
    nbrClick: 'Nombre de clics',
    costView: 'Coût par vue',
    costClick: 'Coût par clic',
    campaignSubtitle: 'Liste de vos campagnes',
    walletSubtitle: 'Solde disponible',
    notificationSubtitle: 'Toutes les réactions sur vos campagnes',
    contactSubtitle: 'Pour nous écrire, nous appeler ou nous visiter',
    transactionHistory: 'Historique des recharges',
    rechargeDate: 'Date de recharge',
    rechargeMethod: 'Moyens',
    rechargeAmount: 'Montant',
    bankCard: 'Carte bancaire',
    remaCode: 'Code de recharge REMA',
    realTransactionHistory: 'Historique de facturation',
    invoiceDate: 'Date de facturation',
    invoiceDesc: 'Description',
    invoiceAmount: 'Montant',
    invoiceAction: 'Action',
    invoice: 'Facture',
    invoiceDownload: 'Télécharger PDF',
    fundingWallet: 'Recharger mon portefeuille',
    fundingWalletRed: 'Le montant rechargé restera dans votre portefeuille et vous pourrez l’utiliser à volonté pour vos campagnes',
    chooseFundMethods: 'Choisissez un moyen de rechargement',
    next: 'Continuer',
    contactUs: 'Nous contacter',
    connect: 'Connexion',
    labelEmail: 'Adresse mail',
    labelPassword: 'Mot de passe',
    noPage: 'Je n\'ai pas encore de compte REMA Business. ',
    createPage: 'Créer un compte',
    createPageReal: 'Créer un compte REMA Business.',
    forgetPassword: 'Mot de passe oublié ?',
    haveAccount: 'J\'ai déjà un compte REMA Business. ',
    signIn: 'Me connecter',
    enterpriseName: 'Nom de l\'entreprise',
    email: 'Email professionnel',
    domain: 'Domaine d\'intervention',
    phone: 'Téléphone',
    description: 'Description',
    country: 'Pays',
    confirmPassword: 'Confirmer mot de passe',
    logo: 'Logo de votre structure',
    selectFile: 'Sélectionner un fichier',
    accept: 'J\'accepte les ',
    acceptCondition: 'conditions d\'utilisation et de confidentialité',
    enterCode: 'Entrer votre code',
    chooseVisaAmount: 'Veuillez saisir le montant en FCFA',
    visaAmount: 'Montant en euro :  ',
    newNotification: 'Nouveau message',
    senderNotification: 'Bernard Marie ONZO',
    haveNotification: 'vous a envoyé un message',
    dateNotification: '31/01/2019',
    campaignNameHere: 'Nom de la campagne ici',
    campaignName: 'Nom de la campagne',
    campaignViews: 'Vues',
    campaignClic: 'Clics',
    campaignBudget: 'Audience',
    campaignCpc: 'CPC',
    campaignCpv: 'CPV',
    campaignBegin: 'Début',
    campaignDuration: 'Durée',
    campaignTitle: 'Titre de ma publicité',
    campaignStatusActive: 'Active',
    campaignStatusDraft: 'Brouillon',
    campaignStatusFinish: 'Terminé',
    campaignStatusBreak: 'En pause',
    campaignStatusInvalid: 'Invalidée',
    campaignStatusWaiting: 'En attente de validation',
    selectCountry: 'Selectionner un pays',
    selectDomain: 'Selectionner un domaine',
    validCrop: 'Valider',
    cancel: 'Annuler',
    successCreateAccount: 'Votre Compte Rema Business a été crée avec succès',
    successFunding: 'Votre portefeuille a été rechargé avec succès',
    deliveredTo: 'Crédité à',
    designation: 'Designation',
    period: 'Période',
    unitPrice: 'Prix Unitaire',
    quantity: 'Quantité',
    totalPrice: 'Prix Total',
    totalRecharge: 'Total rechargé',
    createCanpaign: 'Creer une campagne in App',
    createCanpaignSms: 'Creer une campagne SMS',
    notVisibleName: 'Ce nom n’est pas visible aux utilisateurs',
    text: 'Texte',
    placeOne: 'Ex: Campagne No 1',
    placeTwo: 'Ajouter le contenu texte de votre campagne...',
    images: 'Images',
    imagesSubtitle: 'Ajouter les images de votre campagne',
    actionButton: 'Bouton d’action (facultatif)',
    actionButtonSubtitle: 'Créer un bouton d’appel à action sous votre campagne',
    knowMore: 'En savoir plus',
    download: 'Télécharger',
    createAccount: "S'inscrire",
    contactButton: 'Contacter',
    buyButton: 'Acheter',
    defaultButton: 'Choisir un bouton',
    noneButton: 'Aucun bouton',
    buttonLink: 'Ajouter le lien de redirection du bouton',
    startDate: 'Date de début',
    countryCible: 'Pays cibles',
    cityCible: 'Villes Cibles (Optionnel)',
    chooseCountry: 'Choisir le(s) pays de votre cible',
    chooseCity: 'Choisir le(s) villes de votre cible',
    targetProfession: 'Professions cibles',
    allProfession: 'Toutes les professions',
    age: 'Age',
    allAge: 'Tous les âges',
    genre: 'Genre',
    all: 'Tous',
    nbrDay: 'Nombre de jours',
    publish: 'Publier',
    saveAsdraft: 'Enregistrer comme brouillon',
    portProba: 'Taille Audience',
    totalCost: 'Coût total',
    campaignPreview: 'Aperçu de votre campagne',
    doctor: 'MEDECINS',
    sponsored: 'Sponsorisé',
    readMore: 'Lire Plus',
    lessMore: 'Lire Moins',
    datePopup: 'Elle correspond au début de la diffusion de votre campagne.',
    genrePopup: 'Laissez ce champ vide pour rendre votre campagne visible aux médecins de tout genre.',
    jobPopup: 'Laissez ce champ vide pour rendre votre campagne visible aux médecins de tout statut professionnel',
    agePopup: ' Laissez ce champ vide pour rendre votre campagne visible aux médecins de tous les âges.',
    portPopup: 'La taille audience représente le nombre de professionnels de santé utilisateurs de REMA, correspondant à votre ciblage.',
    totalCostPopup: 'Le coût total correspond au montant qui sera débité de votre portefeuille dès la validation de votre campagne.',
    totalCostPopupTwo: ' Il est calculé en fonction de la taille audience et de la durée (nombre de jours) de diffusion de votre campagne.',
    errorCampaignName: 'Le nom de la campagne est obligatoire',
    errorCampaignText: 'Le texte de la campagne est obligatoire',
    errorImages: 'Veuillez ajouter au moins une image ou un document à votre campagne',
    erroRedirectLink: "Veuillez renseigner un lien de redirection pour votre button d'action",
    errorValidRedirectLink: 'Le lien de redirection est incorrect',
    waitCost: 'Patientez le temps de calcul du Coût',
    confirmCampaignContent: 'Votre campagne sera lancé après la validation par REMA.',
    confirmTitle: 'Confirmation de la campagne.',
    debitAmount: 'Montant à débiter',
    confirm: 'Confirmer',
    insufficientTitle: 'Solde insuffisant !',
    insufficientSubtitle: 'Votre solde est insuffisant.  Veuillez  recharger votre portefeuille.',
    insufficientText: 'Toutefois votre campagne est sauvegardée  comme brouillon et vous pourrez continuer plus tard.',
    fundingMyWallet: 'RECHARGER MON PORTEFEUILLE',
    successCreateTitle: 'Bravo !',
    successCreateText: 'Votre campagne a bien été soumise et est en attente de validation. \n' +
      'Cette opération prend environ 15 minutes.  Veuillez consulter le statut “Active” de votre campagne dans le menu “Campagne”',
    successPublish: 'Campagne Enregistrer',
    sucessSaveIndraft: 'Votre campagne a bien été enregistrée comme brouillon.',
    postpublished: 'Campagne Publier',
    see: 'Aperçu',
    inPause: 'Mettre en pause',
    reUse: 'Réutiliser',
    deletePerm: 'Supprimer définitivement',
    countryResult: 'Résultats par pays',
    seeAll: 'Vue d’ensemble',
    views: 'Vues',
    participate: 'Participer',
    apply: 'Postuler',
    accountDetails: 'Détails du compte',
    logOut: 'Déconnexion',
    domainInt: 'Domaine d’intervention',
    accountNotValidated: 'Compte non encore validé',
    validateMyAccount: 'Valider mon compte',
    emailSent: 'Email envoyé',
    sendedEmailText: 'Vous avez reçu un mail de confirmation.  Veuillez consulter votre boîte et cliquez sur le lien pour confirmer votre compte.',
    successValidate: 'Votre compte REMA Business a été validé avec succès.',
    describeEntrepreprise: 'Décrivez votre entreprise.',
    passwordIndication: '06 caractères minimum',
    telIndication: 'Préciser l\'indicatif',
    successCreateSubtitle: 'Vous pouvez désormais réaliser des campagnes pour touchez des milliers de médécins à travers l’Afrique',
    payment: 'Paiement',
    creditNom: 'CREDIT No: C/RMT',
    desc: 'Description',
    lastThirty: '30 derniers jours',
    lastThree: '3 derniers mois',
    lastSix: '6 derniers mois',
    lastTwelve: '12 derniers mois',
    personalise: 'Personnaliser',
    reStart: 'Reprendre',
    durationInDay: 'Durée en jours',
    durationInDayLabelThree: 'C\' est le nombre de jours que durera votre campagne',
    durationInDayLabelTwo: 'Les campagnes de plus de 30 jours sont plus efficaces',
    accountNotValid: 'Compte non encore validé',
    accountNotValidText: 'Vous devez valider votre compte pour pouvoir faire une campagne publicitaire.Vous pouvez enregistrer votre campagne comme brouillon et finaliser plus tard',
    accountNotValidCancel: 'Supprimer',
    accountNotValidDelete: 'Enregistrer',
    wantToBackText: 'Êtes-vous sûr de vouloir fermer cette campagne ? Elle sera définitivement supprimée.',
    wantToBackCancel: 'Annuler',
    wantToBackClose: 'Fermer',
    searchAccount: 'Recherche de votre compte',
    searchAccountText: 'Veuillez renseigner votre adresse email afin que nous procédions à la vérification de votre compte',
    search: 'Rechercher',
    noComptMatch: 'Aucun compte ne correspond à votre email',
    resetEmailSendTitle: 'Email de réinitialisation envoyé',
    resetEmailSendMessage: 'Un email de réinitialisation de votre mot de passe vous à été envoyé. Veuillez consulter votre boite.',
    olderPassword: 'Ancien mot de passe',
    newPassword: 'Nouveau mot de passe',
    confirmNewPass: 'Confirmer le nouveau de passe',
    fundingWalletTooltip: 'Ce code vous permet de recharger votre portefeuille ',
    fundingWalletTooltipTwo: 'par virement bancaire et autres moyens de paiement.',
    fundingWalletThree: 'Contactez-nous pour recevoir un code recharge REMA.',
    update: 'Modifier',
    invoicdeAt: 'Facturé à',
    pubCampaign: 'Campagne publicitaire',
    ttt: 'Total',
    noneCampaign: 'Aucune campagne retrouvée.',
    noneNotifications: 'Aucune notification.',
    debitedAmount: 'Montant débité',
    totalDebited: 'Total Débité',
    campagneDeleted: 'Campagne supprimée avec succès',
    cityClibleTooltip: "Laissez ce champ vide pour rendre votre campagne visible aux médécins, sur toute l'étendue des pays cibles choisis",
    cancelPaydunya: 'Echec du paiement. Veuillez réessayer.',
    inAppTitle: 'IN APP',
    inAppSubtitle: 'Touchez des médecins dans l\'application mobile REMA',
    inSmsTitle: 'SMS',
    inSmsSubtitle: 'Envoyez des SMS directement aux médecins de REMA',
    charRestant: 'caractères restant.',
    campaignInApp: 'Campagnes in App',
    campaignSms: 'Campagnes SMS',
    sendSMS: 'Portée (SMS)',
    difusion: 'Diffusion',
    campaignValideted: 'Validé',
    sendedSms: 'Sms envoyés',
    budgetTooltip: 'Le coût total correspond au montant débité ou qui sera débité de votre portefeuille dès la validation de votre campagne SMS. Le coût unitaire des SMS étant environ à €0.06 (XOF 40 )en fonction des pays cibles.',
    campaignInAppTooltip: 'Il s\'agit de campagnes natives avec des contenus multimédia, diffusées en continue auprès de votre cible médicale, directement à l\'intérieur du fil d\'actualité sur REMA.',
    campaignSmsTooltip: 'Il s\'agit de la diffusion massive et ponctuelle de messages via SMS, auprès de votre cible médicale.',
    attachFile: 'Pièces Jointes',
    attachFileSubtitle: 'Ajouter des images de votre campagne ou un document PDF ou PPTX',
    attachFileName: 'Nom du fichier',
    filnameError: 'Veuillez renseigner le nom du fichier',
    isForValidation: 'Entrez votre code de validation PayDunya',
    enterEmail: 'Entrez votre email PayDunya',
    'savePublic': 'Sauvegarder l\'audience',
    'saveAudience': 'Audiences',
    'choseAudience': 'Vous pouvez choisir une audience enregistrée',
    'enterName': 'Entrer un nom',
    'findAudience': 'Toutes vos audiences',
    launch: 'Lancer'
  },
  'en': {
    isForValidation: 'Please enter your PayDunya validation code',
    sendedSms: 'Sms envoyés',
    campaignInAppTooltip: 'These are native campaigns with multimedia content, broadcast continuously to your medical target, directly inside the news thread on REMA.',
    campaignSmsTooltip: 'This is the massive and punctual distribution of messages via SMS to your medical target.',
    budgetTooltip: 'The total cost corresponds to the amount debited or which will be debited from your wallet upon validation of your SMS campaign. The unit cost of SMS is around € 0.06 (XOF 40) depending on the target countries.',
    campaignValideted: 'Validated',
    sendSMS: 'Scope (SMS)',
    difusion: 'Diffusion',
    campaignInApp: 'Campaigns in App',
    campaignSms: 'SMS Campaigns',
    charRestant: 'remaining characters.',
    home: 'Home',
    campaigns: 'Campaigns',
    wallet: 'Wallet',
    notification: 'Notifications',
    contact: 'Nous contacter',
    homeSubtitle: 'Dashboard',
    newCampaigns: 'Start a campaign',
    funding: 'Recharger',
    expense: 'Expenses',
    nbrSee: 'Number of views',
    nbrClick: 'Number of clicks',
    costView: 'Cost per view',
    costClick: 'Cost per Click',
    campaignSubtitle: 'Overview of my advertising campaigns',
    notificationSubtitle: 'All the reactions on your ads',
    contactSubtitle: 'To write us, call us or visit us',
    transactionHistory: 'Recharge history',
    rechargeDate: 'Reload date',
    rechargeMethod: 'Means',
    rechargeAmount: 'Amount',
    bankCard: 'Bank card',
    remaCode: 'REMA recharge code',
    realTransactionHistory: 'Historique de facturation',
    invoiceDate: 'Billing date',
    invoiceDesc: 'Description',
    invoiceAmount: 'Amount',
    invoiceAction: 'Action',
    invoice: 'Invoice',
    invoiceDownload: 'Download PDF',
    fundingWallet: 'Reload my wallet',
    fundingWalletRed: 'The reloaded amount will remain in your wallet and you can use it at will for your campaigns',
    chooseFundMethods: 'Choose a recharging method',
    next: 'Next',
    contactUs: 'Contact Us',
    connect: 'Log in',
    labelEmail: 'Mail address',
    labelPassword: 'Password',
    noPage: 'I don\'t have a page yet. ',
    createPage: 'Create my page',
    forgetPassword: 'Forgot your password ?',
    haveAccount: 'I already have a REMA Business account. ',
    signIn: 'Log in',
    enterpriseName: 'Company Name',
    email: 'Professional email',
    domain: 'Area of ​​intervention',
    phone: 'Phone',
    description: 'Description',
    country: 'Country',
    confirmPassword: 'Confirm password',
    logo: 'Logo de votre entreprise',
    selectFile: 'Select a file',
    accept: 'I accept the ',
    acceptCondition: 'terms of use and confidentiality',
    enterCode: 'Enter your code',
    chooseVisaAmount: 'Please enter the amount',
    visaAmount: 'Amount',
    newNotification: 'New message',
    senderNotification: 'Bernard-Marie ONZO',
    haveNotification: 'sent you a message',
    dateNotification: '31/01/2019',
    campaignNameHere: 'Campaign name here',
    campaignName: 'Campaign name',
    campaignViews: 'Views',
    campaignClic: 'Clicks',
    campaignBudget: 'Audience',
    campaignCpc: 'CPC',
    campaignCpv: 'CPV',
    campaignBegin: 'Beginning',
    campaignDuration: 'Duration',
    campaignTitle: 'Title of my advertisement',
    campaignStatusActive: 'Active',
    campaignStatusDraft: 'Rough draft',
    campaignStatusFinish: 'Finished',
    campaignStatusBreak: 'On break',
    campaignStatusInvalid: 'Invalidated',
    campaignStatusWaiting: 'Waiting for validation',
    selectCountry: 'Choose country',
    selectDomain: 'Select a domain',
    validCrop: 'Valid',
    cancel: 'Cancel',
    successCreateAccount: 'Votre compte a été créé avec succès',
    successFunding: 'Success Funding',
    deliveredTo: 'Delivered to',
    designation: 'Designation',
    period: 'Period',
    unitPrice: 'Unit Price',
    quantity: 'Quantity',
    totalPrice: 'Total Price',
    createCanpaign: 'Create a campaign in App',
    createCanpaignSms: 'Create a campaign SMS',
    notVisibleName: 'This name is not visible to users',
    text: 'Texte',
    placeOne: 'Ex: Campaign No 1',
    placeTwo: 'Add content for campaign...',
    images: 'Images',
    imagesSubtitle: 'Add campaign images',
    actionButton: 'Action button (optional)',
    actionButtonSubtitle: 'Create a call to action button under your campaign',
    knowMore: 'Find out more',
    download: 'Download',
    createAccount: 'Register',
    contactButton: 'Contact',
    buyButton: 'Buy',
    defaultButton: 'Choose a button',
    noneButton: 'No button',
    buttonLink: 'Add button redirect link',
    startDate: 'Start Date',
    countryCible: 'Target countries',
    chooseCountry: 'Choose the country (ies) of your target',
    chooseCity: 'Choose the city (ies)  of your target',
    targetProfession: 'Target Professions',
    allProfession: 'All Professions',
    age: 'Age',
    allAge: 'All ages',
    genre: 'Kind',
    all: 'All',
    nbrDay: 'Number of days',
    publish: 'Publish',
    saveAsdraft: 'Save as a draft',
    portProba: 'Likely reach',
    totalCost: 'Total cost',
    campaignPreview: 'Overview of your campaign',
    doctor: 'DOCTORS',
    sponsored: 'Sponsored',
    readMore: 'Read More',
    lessMore: 'Read Less',
    datePopup: 'It corresponds to the start of the broadcast of your campaign.',
    genrePopup: 'Leave this field blank to make your campaign visible to doctors of all kinds.',
    jobPopup: 'Leave this field blank to make your campaign visible to doctors of any professional status',
    agePopup: 'Leave this field blank to make your campaign visible to doctors of all ages.',
    portPopup: 'The likely scope represents the number of physicians using REMA, corresponding to your targeting.',
    totalCostPopup: 'Le coût total correspond au montant qui sera débité de votre portefeuille dès la validation de votre campagne.',
    totalCostPopupTwo: 'Il est calculé en fonction de la portée probable et de la durée (nombre de jours) de diffusion de votre campagne.',
    errorCampaignName: 'Campaign name is required',
    errorCampaignText: 'Campaign text is required',
    errorImages: 'Please add at least one image to your campaign',
    erroRedirectLink: 'Please enter a redirect link for your action button',
    errorValidRedirectLink: 'The redirect link is incorrect',
    waitCost: 'Wait for the cost calculation time',
    confirmCampaignContent: 'This amount will be debited from your wallet balance upon validation of your campaign by REMA.',
    confirmTitle: 'Confirmation of the campaign.',
    debitAmount: 'Amount to be debited',
    confirm: 'Confirm',
    insufficientTitle: 'Insufficient balance !',
    insufficientSubtitle: 'Your balance is insufficient. Please recharge your wallet.',
    insufficientText: 'However, your campaign is saved as a draft and you can continue later.',
    fundingMyWallet: 'RECHARGE MY WALLET',
    successCreateTitle: 'Bravo !',
    successCreateText: 'Your campaign has been successfully submitted and is awaiting validation.\n' +
      'This takes approximately 15 minutes. Please consult the “Active” status of your campaign in the “Campaign” menu',
    successPublish: 'Campaign Saved',
    postpublished: 'Campagne Published',
    see: 'Overview',
    inPause: 'Pause',
    reUse: 'Reuse',
    deletePerm: 'Delete permanently',
    countryResult: 'Results by country',
    seeAll: 'Overview',
    participate: 'Participate',
    apply: 'Apply',
    accountDetails: 'Account details',
    logOut: 'Sign Out',
    domainInt: 'Area of ​​intervention',
    accountNotValidated: 'Account not yet validated',
    validateMyAccount: 'Validate my account',
    emailSent: 'Email sent',
    sendedEmailText: 'You have received a confirmation email. Please check your box and click the link to confirm your account.',
    successValidate: 'Your REMA Business account has been successfully validated.',
    describeEntrepreprise: 'Décrivez votre entreprise.',
    passwordIndication: '06 caractères minimum',
    telIndication: 'Préciser l\'indicatif',
    payment: 'Payment',
    creditNom: 'CREDIT No: C/RMT',
    totalRecharge: 'Total rechargé',
    desc: 'Description',
    lastThirty: 'Last 30 days',
    lastThree: 'Last 3 months',
    lastSix: 'Last 6 months',
    lastTwelve: 'Last 12 months',
    personalise: 'Personalize',
    reStart: 'Re - Start',
    durationInDayLabelThree: 'C\' est le nombre de jours que durera votre campagne ',
    durationInDayLabelTwo: ' Les campagnes de plus de 30 jours sont plus efficaces',
    accountNotValid: 'Compte non encore validé',
    accountNotValidText: 'Vous devez valider votre compte pour pouvoir faire une campagne publicitaire.Vous pouvez enregistrer votre campagne comme brouillon et finaliser plus tard',
    accountNotValidCancel: 'Enregistrer',
    accountNotValidDelete: 'Supprimer',
    cityCible: 'Target Cities (Optional)',
    cityClibleTooltip: 'Leave this field blank to make your campaign visible to doctors, across the whole range of target countries chosen',
    cancelPaydunya: 'Payment failed. Try Again',
    'savePublic': 'Save audience',
    launch: 'Launch'
  }
}
// let locale = navigator.language
export const i18n = new VueI18n({
  fallbackLocale: 'fr',
  locale: 'fr', // TODO remplace this with locale variable for multilangue
  messages: lang
})
